import React from "react";
import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWind, faDroplet } from "@fortawesome/free-solid-svg-icons"
import { FiSunset } from "react-icons/fi";



export default function Current({ weatherInfo, location }) {
    return (
      <div className="Weather">
        <h2 className="other-info__city">
          {location.city || location.town},{" "}
          {location.state || location.country.toUpperCase()}
        </h2>
        <div className="Weather__info">
          <img
            className="Weather__icon"
            src={
              "https://openweathermap.org/img/wn/" +
              weatherInfo.current.weather[0].icon +
              "@2x.png"
            }
            alt={weatherInfo.current.weather[0].main}
          />
          <ul className="weather__list">
            <li className="list__temperature">
              {Math.round(weatherInfo.current.temp)}
              <span className="list__temperature-symbol">°</span>
            </li>
            <li className="other-info__clouds">
              {weatherInfo.current.weather[0].description}
            </li>
            <li className="list__info list__temp-minmax">
              {"H: "}
              {Math.round(weatherInfo.daily[0].temp.max)}
              <span className="temperature__symbol">°</span> {"L: "}
              {Math.round(weatherInfo.daily[0].temp.min)}
              <span className="temperature__symbol">°</span>
            </li>
          </ul>
        </div>
        <div className="Weather__other-info">
          <ul className="weather__list">
            <li className="list__info list__card">
              <FontAwesomeIcon
                icon={faDroplet}
                className="weather__other-icons"
              />
              Humidity: {weatherInfo.current.humidity}%{" "}
            </li>
            <li className="list__info list__card">
              <FontAwesomeIcon icon={faWind} className="weather__other-icons" />
              Wind: {Math.round(weatherInfo.current.wind_speed * 3.6)} km/h
            </li>
            <li className="list__info list__card">
              <FiSunset className="weather__other-icons_sunset"/>
              Sunset: {Moment.unix(weatherInfo.daily[0].sunset).format("hh:mm")}
            </li>
          </ul>
        </div>
      </div>
    );
}