import axios from "axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import {
  faListUl,
  faCar,
  faPersonDigging,
} from "@fortawesome/free-solid-svg-icons";

function ShowTraffic() {
  const [verkeer, setVerkeer] = useState();
  const [toggle, setToggle] = useState(false);
  const getVerkeer = () => {
    axios.get("/api/getVerkeer").then((res) => {
      setVerkeer(res.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    getVerkeer();

    const interval = setInterval(() => {
      getVerkeer();
    }, 180000);

    return () => clearInterval(interval);
  }, []);

  const Location = ["Ridderkerk", "Rotterdam", "Dordrecht"];

  const fileCount = (verkeer) => {
    const fileList = [];
    Location.map((item) =>
      fileList.push(
        verkeer.obstructions.filter(
          (e) => e.directionText.includes(item) && e.obstructionType === 4
        )
      )
    );
    const fileCount = [].concat.apply([], fileList);
    return fileCount.length;
  };

  const vertraging = (delay) => {
    const hourInFloat = delay / 60;
    const hour = Math.trunc(hourInFloat);
    const remainder = hourInFloat - hour;
    const minute = remainder * 60;
    if (minute === 0) {
      return hour + " uur";
    } else if (delay > 60) {
      console.log(minute);
      return hour + " uur " + Math.ceil(minute) + " min";
    } else {
      return delay + " min";
    }
  };
  return toggle ? (
    <>
      <button className="verkeer__toggleMap" onClick={() => setToggle(!toggle)}>
        <FontAwesomeIcon icon={faListUl} />
      </button>
      <iframe
        className="traffic-map"
        src="https://www.livetraffic.nl"
        frameBorder="0"
        scrolling="no"
        title="Verkeer File Map"
        width="100%"
        height="125%"
        overflow="hidden"
        loading="eager"
        allowFullScreen={true}
      ></iframe>
    </>
  ) : verkeer ? (
    <>
      <div className="verkeer__fileCount">Files: {fileCount(verkeer)}</div>
      <div>
        <button
          className="verkeer__toggleMap"
          onClick={() => setToggle(!toggle)}
        >
          <FontAwesomeIcon icon={faMap} />
        </button>
      </div>
      <div className="verkeer-scroll">
        <div className="space"></div>
        {Location.map((item) =>
          verkeer.obstructions
            .filter((e) => e.directionText.includes(item))
            .map((item, i) => (
              <div className="verkeer__cards" key={i}>
                <div className="verkeer__title">
                  <div
                    className="roadNumber"
                    style={{
                      backgroundColor: item.roadNumber.includes("A")
                        ? "#DD3033"
                        : item.roadNumber.includes("E")
                        ? "#007F00"
                        : item.roadNumber.includes("N")
                        ? "#FFDC36"
                        : "white",
                      border: item.roadNumber.includes("A")
                        ? "1px solid #E50007"
                        : item.roadNumber.includes("E")
                        ? "1px solid #0D7C17"
                        : item.roadNumber.includes("N")
                        ? "1px solid #FFD416"
                        : "1px solid white ",
                      color: item.roadNumber.includes("N") ? "black" : "white",
                    }}
                  >
                    {item.roadNumber}
                  </div>
                  <div className="directionText">{item.directionText}</div>
                </div>
                <div className="verkeer-description">{item.title}</div>
                {item.obstructionType === 1 ? (
                  <div className="werkzaamheden">
                    <FontAwesomeIcon icon={faPersonDigging} />
                  </div>
                ) : null}
                <div className="fileIcon">
                  {item.obstructionType === 4 ? (
                    <FontAwesomeIcon icon={faCar} />
                  ) : null}
                </div>
                <div className="locationText">
                  {item.locationText}
                    {" "}
                  {item.delay ? (
                    <div className="vertraging"> {vertraging(item.delay)}</div>
                  ) : null}
                </div>
              </div>
            ))
        )}
      </div>
    </>
  ) : (
    <>
      <div className="verkeer-nodata"> Geen data gevonden </div>
    </>
  );
}

export default ShowTraffic;

// obstructionType =
// 1= Werkzaamheden
// 2= Ongeval
// 4= Stilstaand of langzaam verkeer
// 5= Brand
// 6= Omleiding
// 7= Weg afgesloten
// 9= Dier op weg
// 20= Voorwerp op verkeer
// 21= Pechgeval
