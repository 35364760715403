
import React, { useEffect } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';



const DashboardCalendar = ({setShow, setAppointment, appointments, setAppointments}) => {
    useEffect(() => {
        getAppointments()
        // DestroyAppoinments()
     }, []);

    const { addToast } = useToasts();   
    
    const dateClicked = (cell) => {
        const appointment = prompt("Wat ga je doen?")
        axios.post('/api/calendar/store', {
            date: cell.dateStr,
            appointment
        }).then(res => {
            addToast(res.data, { appearance: 'success' });
            getAppointments()
        })
    }

    const getAppointments = () => {
        axios.get('/api/calendar/index')
            .then(res => {
                setAppointments(res.data)
            })

    }
    

    return (
        <>
        <FullCalendar
                    plugins={[interactionPlugin, dayGridPlugin, listPlugin]}
                    events={appointments}
                    listDaySideFormat = {true}
                    eventColor = '#D60007'
                    eventTextColor = '#ffffff'
                    // initialView = 'listWeek'
                    initialView="dayGridMonth"
                    // editable = 'true'
                    weekends={false}
                    aspectRatio =  '1.2'
                    // scrollTime = '00:00'
                    showNonCurrentDates = {false}
                    fixedWeekCount={false}
                    listDayFormat = {true}
                    selectable = {true}
                    dateClick={(cell) => {
                        dateClicked(cell)
                    }}

                    eventClick ={ (cell) => {
                        setShow(true)
                        setAppointment(cell.event)
                    }}
                    headerToolbar={{
                        left: 'dayGridMonth,dayGridWeek,listWeek',
                        center:'title',
                        right: 'prev,next,today'
                    }}
                    titleFormat= {{month: 'long'}}
                />
        </>

    )
    
}

export default DashboardCalendar




