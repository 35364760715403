import axios from "axios";
import React, { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";

const Modal = (props) => {
  const { addToast } = useToasts();
  const [titleValue, setTitleValue] = useState();
  const [dateValue, setDateValue] = useState();

  useEffect(() => {
    if (props.appointment) {
      setTitleValue(props.appointment.title);
      setDateValue(props.appointment.startStr);
    }
  }, [props.appointment]);

const updateAppointment =()=> {
    axios.post('/api/calendar/update', {
        start: dateValue,
        title: titleValue,
        id: props.appointment.id,
    }).then(res => {
        addToast(res.data, { appearance: 'success' });
        axios.get("/api/calendar/index").then((res) => {
            props.setAppointments(res.data);
            props.setShow(false);
          });
    })

}

  const DestroyAppointments = () => {
    axios
      .post("/api/calendar/destroy", {
        id: props.appointment.id,
      })
      .then((res) => {
        addToast(`${props.appointment.title} is verwijderd.`, {
          appearance: "success",
        });

        axios.get("/api/calendar/index").then((res) => {
          props.setAppointments(res.data);
          props.setShow(false);
        });
      });
  };

  return props.appointment ? (
    <div className={`modal ${props.show ? "show" : ""}`}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title">{props.title}</h4>
        </div>
        <div className="modal-body">
          <form className="modal-form">

            <label>
              {" "}
              Event:{" "}
              <input
                type="text"
                name="title"
                value={titleValue}
                onChange={(e) => {
                  setTitleValue(e.target.value);
                }}
              />{" "}
            </label>

            <label>
              {" "}
              Datum:{" "}
              <input
                type="date"
                name="datum"
                value={dateValue}
                onChange={(e) => {
                  setDateValue(e.target.value);
                }}
              />
            </label>

          </form>
        </div>
        <div className="modal-footer">
          <button className="modal-button2" 
          onClick={() => updateAppointment()}
          >Update</button>
          <button
            onClick={() => DestroyAppointments()}
            className="modal-button3"
          >
            Delete
          </button>
          <button onClick={props.onClose} className="modal-button1">
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
