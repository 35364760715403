import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import Moment from "moment";
import { useToasts } from 'react-toast-notifications';
import Countdowntimer from "../refreshCounter";


const noData = <div className="prof-it-pulse"></div>


createTheme("Uptime-Robot", {
    text: {
        primary: "#000000",
    },
    background: {
        default: "transparent",
    },
    divider: {
      default: "transparent",
    },
});

const columns = [
  {
    name: "DomainName",
    sortable: true,
    selector: (row) => (
      <a href={row.friendly_name} target="_blank" rel="noopener noreferrer">
        {row.friendly_name}
      </a>
    ),
    style: {
      paddingLeft: "6px",
    },
  },

  {
    name: "Time",
    sortable: true,
    selector: function (row) {
      const formatted = Moment.unix(row.logs[0].datetime).format(
        "h:m:sa DD/MM/YY"
      );
      return formatted;
    },
    style: {
      justifyContent: "end",
      paddingRight: "6px",
    },
  },
];



function UptimeRobot() {
    const [monitors, setMonitors] = useState();
    const [uptimeStatus, setUptimeStatus] = useState();
    const limitReached = "Request limit reached"


    const getMonitors = () => {
        axios.get("/api/getMonitor").then((res) => {
            console.log(res.data);
            const monitors = res.data.monitors;
            const stat = res.data.stat;
            setMonitors(monitors);
            if (monitors && monitors.length > 0) {
                setUptimeStatus("Something is Wrong");
            } else if (monitors && monitors.length === 0) {
                setUptimeStatus("All servers operational");
            }   
             else if (stat === "fail") {
                setUptimeStatus("Failed to connect");
            }   
            else {
                setUptimeStatus(limitReached)
            }

        });

    };

    const StatusColor = () => {
        let color;
        if (monitors && monitors.length > 0) {
            color= "#DD3033";
        }
        else if (monitors && monitors.length === 0) {
            color= "#3BD671";
        }
        else if (uptimeStatus === limitReached) {
            color= "#555555d0"
        }
        return color
       };

       const PulseColor = () => {
        let pulse;
        if (monitors && monitors.length > 0) {
            pulse= "pulse-red 2s infinite"
        }
        else if (monitors && monitors.length === 0) {
            pulse= "pulse-green 2s infinite"
        }
        return pulse
       };

       const StatusStyle = {
           backgroundColor: StatusColor(),
           animation: PulseColor(),
       }
       

    useEffect(() => {
        getMonitors();

        const interval = setInterval(() => {
            getMonitors();
        }, 120000);

        return () => clearInterval(interval)
    }, []);

    const { addToast } = useToasts()
    const refresh =() =>{

    addToast("Uptime has been refreshed", {
        appearance: 'success',
        autoDismiss: true,
        style: {
            height : "35px",
        }
      })
}

return (
  <Fragment>
    <div className="uptime-status">
      <div className="status__symbol" style={StatusStyle}></div>
      {uptimeStatus}
    </div>
      <Countdowntimer />
    <div className="card">
      <DataTable
        columns={columns}
        data={monitors}
        noDataComponent={noData}
        theme="Uptime-Robot"
        noTableHead="true"
      />
    </div>
    <button
      onClick={() => {
        getMonitors();
        refresh();
      }}
      className="refreshButton"
    >
      <i className="fa fa-refresh"></i> Refresh
    </button>
  </Fragment>
);
}


export default UptimeRobot;
