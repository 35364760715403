// import react from "react";

function GetDate() {
    const date = new Date();

    const datum = date.getDate();
    const month = [ " Jan ", " Feb ", " Mar ", " Apr ", " May ", " Jun ", " Jul ", " Aug ", " Sep ", " Oct ", " Nov ", " Dec " ];

    const Year = date.getFullYear();
    const weekday = ["Sun ", "Mon ", "Tue ", "Wed ", "Thu ", "Fri ", "Sat "];

    return weekday[date.getDay()] + datum + month[date.getMonth()] + Year;
}

export default GetDate;
