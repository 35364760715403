import React from "react";

function Loader() {
  return <>
  {/* <div className="Loader"></div> */}
  <div className="cloud-container">
    <div className="cloud">
      <div className="rain">
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
        <div className="droplet"></div>
      </div>
    </div>
  </div>
  </>;
}

export default Loader;