import React from "react";

function Warning() {
  return (
    <>
    
    <div className="Warning">
      <h2> No location found </h2>
    </div>
    <div className="cloud"></div>
    </>
  );
}

export default Warning;