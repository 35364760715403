import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesUp, faAngleUp, faEquals, faAngleDown, faAnglesDown } from '@fortawesome/free-solid-svg-icons'
import "../../index.css"

const columns = [
  {
    name: "id",
    sortable: false,
    cell: (row) => (
      <div className="jira__cards">
        <div className="taskName">{row.fields.summary}</div>
        <div className="jira__avatar">
          <img src={row.fields.assignee?.avatarUrls["32x32"]} alt=""/>
          {row.fields.assignee?.displayName ? row.fields.assignee.displayName : "Geen persoon  "} 
          <div className="jira__priority">
            {row.fields.priority.name === "Lowest" ? <FontAwesomeIcon icon={faAnglesDown} className="jira__lowest-priority"/>
              : row.fields.priority.name === "Low" ? <FontAwesomeIcon icon={faAngleDown} className="jira__low-priority"/>
              : row.fields.priority.name === "Medium" ? <FontAwesomeIcon icon={faEquals} className="jira__medium-priority" /> 
              : row.fields.priority.name === "High" ? <FontAwesomeIcon icon={faAngleUp} className="jira__high-priority" />
              : row.fields.priority.name=== "Highest"? <FontAwesomeIcon icon={faAnglesUp} className="jira__highest-priority" /> 
              : row.fields.priority.name}
            </div>
          </div>
          <div className="epic">
          {row.fields.parent.fields.summary}
          
          </div>
      </div>
), },
  
];

createTheme("JiraTheme", {
  text: {
      primary: "#000000",
  },
  background: {
      default: "transparent",
  },
  divider: {
    default: "transparent",
  },
});

const JiraStyles = {
  cells: { 
    style: {
      padding: "0"
    }
  },
  headCells: {
    style: {
      color: "white",
      padding: "0",
      backgroundColor: "red"
    }
  },
}

function JiraBoards(){
  const [inProgress, setInProgress] = useState();
  const [backlog, setBacklog] = useState();
  const [testing, setTesting] = useState();
  const [sprint, setSprint] = useState();

    const getJira= () => {
      axios.get("/api/getJira").then((res) => {
      setInProgress(res.data.inProgress);
      setBacklog(res.data.backlog);
      setTesting(res.data.testing);
      setSprint(res.data.sprint);
      console.log(res.data)
    });

    }

  useEffect(() => {
    getJira()

    const interval = setInterval(() => {
      getJira();
  }, 300000);

  return () => clearInterval(interval)

  }, []);

  return (
    <>
      <div className="jira__sprint">
        {sprint}
        </div>
      {backlog ? (
        <div className="jira__column">
          <div className="jira__logo"></div>
          <DataTable
            title="Backlog"
            columns={columns}
            data={backlog}
            theme="JiraTheme"
            customStyles={JiraStyles}
            noTableHead="true"
            noDataComponent="backlog"
            dense
          />
        </div>
      ) : null}
      {inProgress ? (
        <div className="jira__column">
          <DataTable
            title="In Progress"
            columns={columns}
            data={inProgress}
            theme="JiraTheme"
            customStyles={JiraStyles}
            noTableHead="true"
            noDataComponent="inProgress"
            dense
          />
        </div>
      ) : null}
      {testing ? (
        <div className="jira__column">
          <DataTable
            title="Test"
            columns={columns}
            data={testing}
            theme="JiraTheme"
            customStyles={JiraStyles}
            noTableHead="true"
            noDataComponent="testing"
            dense
          />
        </div>
      ) : (
        <div className="jira__cards" style={{paddingTop: "0.57rem", borderRadius: "30px"}}>
          Geen data gevonden
        </div>
      )}
    </>
  );
}

export default JiraBoards;