import React, { useState } from "react";

export default function Search({ searchCity }) {
    const [currentCity, setCurrentCity] = useState("");

    function handleInputChange(event) {
        setCurrentCity(event.target.value);
    }

    function handleButtonClick() {
        if (currentCity.trim() === "") return;
        searchCity(currentCity);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") handleButtonClick();
    }

    const [searchOpen, setSearchOpen] = useState(false);

    const handleToggle = () => {
        setSearchOpen(!searchOpen);
    };

    return (
        <div className="toggleSearch">
            <button onClick={handleToggle} className="toggleButton">
                {searchOpen}<i className="fa fa-search"></i>
            </button>
            <ul className={`searchMenu ${searchOpen ? "showMenu" : ""}`}>

                <div className="Search">
                    <label className="Search__label">
                        <input
                            className="Search__input"
                            value={currentCity}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                        />
                    </label>
                    <button
                        className="Search__button"
                        onClick={handleButtonClick}
                    >
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </ul>
        </div>
    );
}
