import React, { useState, useRef } from 'react'
import styles from "./AudioPlayer.module.css";
import { BiPlay } from "react-icons/bi"
import { BiPause } from "react-icons/bi"
// import {MdArrowDropDown} from "react-icons/md"
// import Select from 'react-select';


const AudioPlayer = () => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);

  // references
  const audioPlayer = useRef();   // reference our audio component
  const animationRef = useRef();  // reference the animation

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }


const [value, setValue] = React.useState("http://playerservices.streamtheworld.com/api/livestream-redirect/TLPSTR13.mp3")

const handleChange = (event) => {
  setValue(event.target.value);
  setIsPlaying(false);
  
};

//  voor meer muziek kanalen (gebruik de mp3 link)
//  https://www.hendrikjansen.nl/henk/streaming.html 

  return (
    <div className={styles.audioPlayer}>
        <audio ref={audioPlayer} src={value}  preload="metadata"> </audio>
    
      <button onClick={togglePlayPause} className={styles.playPause}>
        {isPlaying ? <BiPause /> : <BiPlay className={styles.play} />}
      </button>
  
<select className={styles.dropdownmenu} value={value} onChange={handleChange}> 
  <option className={styles.dropdownitem} value={"http://playerservices.streamtheworld.com/api/livestream-redirect/RADIO538.mp3"}> Radio 538</option>
  <option className={styles.dropdownitem} value={"http://playerservices.streamtheworld.com/api/livestream-redirect/TLPSTR13.mp3"}> 538 Top 50</option>
  <option className={styles.dropdownitem} value={"http://playerservices.streamtheworld.com/api/livestream-redirect/TLPSTR09.mp3"}> 538 NonStop </option>
  <option className={styles.dropdownitem} value={"https://stream.qmusic.nl/nonstop/mp3"}>Qmusic NonStop</option>
  <option className={styles.dropdownitem} value={"https://icecast.omroep.nl/radio1-bb-mp3"}>NPO Radio 1</option>
</select>
    </div>
  )
}
export { AudioPlayer }