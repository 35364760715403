import React, { useEffect, useState } from "react";

function GetTime() {
    const [timeState, SetTimeState] = useState();
    useEffect(() => {
        setInterval(() => {
            const date = new Date();
            SetTimeState(date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        }, 1);
    }, []);

    return <>{timeState}</>;
}

export default GetTime;
