import axios from "axios";
import React, { useEffect, useState } from "react";

function ToonAlgemeenNieuws() {
  const [AlgemeenNieuws, setAlgemeenNieuws] = useState();

  const getNieuwsdata = () => {
    axios.get("/api/getNieuwsdata").then((res) => {
      const AlgemeenNieuws = res.data;
      setAlgemeenNieuws(AlgemeenNieuws);
      console.log(AlgemeenNieuws);
    });
  };

  useEffect(() => {
    getNieuwsdata();

    const interval = setInterval(() => {
      getNieuwsdata();
    }, 1800000);

    return () => clearInterval(interval);
  }, []);

  return AlgemeenNieuws ? (
    <>
      <div className="algemeenNieuws-scroll">
        {AlgemeenNieuws.articles.map((item, i) => (
          <div
            key={i}
            className="algemeenNieuws-kaartjes"
            // style={{
            //   backgroundImage: `url(${item.urlToImage})`,
            //   backgroundSize: "cover",
            // }}
          >
            <div className="algemeenNieuws-text">
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
              {/* <iframe name="book" src={item.url}></iframe> */}
            </div>
            {item.urlToImage ? (
              <img
                className="afbeeldingalgemeennieuws"
                src={item.urlToImage}
                alt={item.title}
              />
            ) : null}
          </div>
        ))}
      </div>
    </>
  ) : (
    <div className="nodata-algemeenNieuws"> Geen nieuws gevonden </div>
  );
}

export default ToonAlgemeenNieuws;