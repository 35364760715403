import "./App.css";
import React, { useState } from "react";
import axios from "axios";
import UptimeRobot from "./components/uptime-robot";
import JiraBoards from "./components/jira";
import GetTime from "./components/time";
import GetWeather from "./components/weather/getWeather";
import GetDate from "./components/date";
import ShowTraffic from "./components/verkeer";
import DashboardCalendar from "./components/calendar";
import Modal from "./components/calendar/Modal";
import { AudioPlayer } from "./components/Radio";
import ToonAlgemeenNieuws from "./components/algemeennieuws";


axios.defaults.baseURL = "https://api.dashboard.prof-it4all.nl";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [appointment, setAppointment] = useState();
  const [appointments, setAppointments] = useState();

  return (
    <div className="dashboard">
      <div className="dashboard-grid">
        <div className="top">
          <div className="date">
            <GetDate />
          </div>
        </div>
        <div className="top">
          <div className="time">
            <GetTime />
          </div>
        </div>
        <div className="top">
          <AudioPlayer />
        </div>
        <div className="top">
          <a href="https://api.dashboard.prof-it4all.nl">
            <h2>Login</h2>
          </a>
        </div>

        <div className="widget">
          <GetWeather />
        </div>

        <div className="widget">
          <UptimeRobot />
        </div>

        <div className="widget calendar">
          <DashboardCalendar
            setShow={setShow}
            setAppointment={setAppointment}
            appointments={appointments}
            setAppointments={setAppointments}
          />
        </div>

        <div className="widget verkeer">
          <ShowTraffic />
        </div>

        <div className="widget algemeenNieuws">
          <ToonAlgemeenNieuws />
        </div>

        <div className="widget">
          <iframe
            src="https://prof-it4all.nl/nieuws/"
            title="Prof-IT4all News"
            frameBorder="0"
            width="100%"
            height="100%"
            sandbox="allow-scripts"
          ></iframe>
        </div>

        <div className="widget jira">
          <div className="jiraboard">
            <JiraBoards />
          </div>
        </div>

        <div className="Modal-App" onClick={() => setShow(false)}>
          <Modal
            title="Update of Delete event"
            setAppointments={setAppointments}
            appointment={appointment}
            onClose={() => setShow(false)}
            setShow={setShow}
            show={show}
          >
            {appointment ? <p> {appointment.title} </p> : "Geen data gevonden"}
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;